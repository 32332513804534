import React, { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import NewProjectModal from "../projects/createProject";
import axios from "axios";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";

const ProjectModal = ({ isOpen, onClose, selectedProject }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        {selectedProject ? selectedProject.name : "Project"}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>
          {selectedProject
            ? `This is a placeholder for ${selectedProject.name} project details.`
            : "No project selected"}
        </Text>
      </ModalBody>
    </ModalContent>
  </Modal>
);

const Home = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectVersion, setSelectedProjectVersion] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const email = user.email;

  const {
    isOpen: isProjectModalOpen,
    onOpen: onProjectModalOpen,
    onClose: onProjectModalClose,
  } = useDisclosure();
  const {
    isOpen: isNewProjectModalOpen,
    onOpen: onNewProjectModalOpen,
    onClose: onNewProjectModalClose,
  } = useDisclosure();
  const [signedUrl, setSignedUrl] = useState(null);
  const [textFileContent, setTextFileContent] = useState("");
  const [textFileUrl, setTextFileUrl] = useState("");
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isProjectVersionSelected, setIsProjectVersionSelected] =
    useState(false);

  const sidebarStyle = isProjectSelected
    ? {
        transform: "translateX(-100%)",
        opacity: 0,
        transition: "transform 0.4s ease-in-out, opacity 0.4s ease-in-out",
        width: "550px",
        overflow: "hidden",
      }
    : {
        transform: "translateX(0)",
        opacity: 1,
        transition: "transform 0.4s ease-in-out, opacity 0.4s ease-in-out",
        width: "600px",
        overflow: "hidden",
      };

  const mainContentStyle = isProjectSelected
    ? {
        filter: "none",
        transition: "filter 0.4s ease-in-out",
      }
    : {
        filter: "blur(10px)",
        transition: "filter 0.4s ease-in-out",
      };

  const handleSidebarClick = () => {
    setIsProjectSelected(!isProjectSelected);
  };

  const handleSelectProject = async (project) => {
    setSelectedProject(project);
    setIsProjectSelected(true);
    setIsProjectVersionSelected(false);
    setSelectedProjectVersion(null);
    setTextFileContent("");
    setTextFileUrl("");
    onOpen();
    let filePath = project.file_path + "/" + project.document_name;

    try {
      const response = await axios.post(
        process.env.REACT_APP_GENERATE_PRESIGNED_URL_API,
        {
          key: filePath,
          action: "getObject",
        }
      );
      const preSignedUrl = JSON.parse(response.data.body).url;
      setSignedUrl(preSignedUrl);

      if (project.bert_text) {
        let textFileContent = "";
        project.bert_text.forEach((textBlock) => {
          textFileContent += textBlock + "\n\n"; // Add a newline character to separate paragraphs
        });
        setTextFileContent(textFileContent);
        const textFileBlob = new Blob([textFileContent], {
          type: "text/plain",
        });
        const textFileUrl = URL.createObjectURL(textFileBlob);
        setTextFileUrl(textFileUrl);
      }
    } catch (error) {
      console.error("Error loading project data:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
        {
          action: "getProjects",
          email: email,
        }
      );

      if (response.data.statusCode === 200 && response.data.body) {
        const body = JSON.parse(response.data.body);
        if (body.projects && body.projects.length > 0) {
          setProjects(body.projects);
          //handleSelectProject(body.projects[0]);
        }
      } else {
        console.error("Error retrieving projects:", response);
      }
    } catch (error) {
      console.error("Error retrieving projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [email]);

  const handleNewProjectCreation = () => {
    fetchProjects();
  };

  return isLoading ? (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="xl" />
    </Box>
  ) : (
    <Flex width="100%" flexDirection="row">
      <Box
        width={isProjectSelected ? "0px" : "450px"}
        overflowY="auto"
        style={sidebarStyle}
        position="fixed"
        zIndex="10"
        backgroundColor="white"
      >
        <Sidebar
          user={user}
          projects={projects}
          setProjects={setProjects}
          onSelectProject={handleSelectProject}
          selectedProject={selectedProject}
          isProjectVersionSelected={isProjectVersionSelected}
          onNewProjectModalOpen={onNewProjectModalOpen}
          onHandleSidebarClick={handleSidebarClick}
        />
      </Box>
      {selectedProject && (
        <Box flex="1" style={mainContentStyle}>
          <MainContent
            user={user}
            projectData={projects}
            selectedProject={selectedProject}
            textFileUrl={textFileUrl}
            textContent={textFileContent}
            handleSidebarClick={handleSidebarClick}
            isProjectSelected={isProjectSelected}
            selectedProjectVersion={selectedProjectVersion}
            setSelectedProjectVersion={setSelectedProjectVersion}
            isProjectVersionSelected={isProjectVersionSelected}
            setIsProjectVersionSelected={setIsProjectVersionSelected}
          />
        </Box>
      )}
      {isProjectSelected && (
        <Box
          position="fixed"
          left="0"
          top="50%"
          transform="translateY(-50%)"
          zIndex="10"
          backgroundColor="gray.200"
          width="20px"
          height="80%" // Making the box taller
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={handleSidebarClick}
          _hover={{ backgroundColor: "gray.300" }}
          sx={{
            clipPath: "polygon(0 0, 0 15%, 100% 30%, 100% 70%, 0 85%, 0 100%)",
          }}
          onMouseEnter={(e) => {
            e.stopPropagation();
            handleSidebarClick();
          }}
        >
          <ChevronRightIcon />
        </Box>
      )}
      <ProjectModal
        isOpen={isProjectModalOpen}
        onClose={onProjectModalClose}
        selectedProject={selectedProject}
      />
      <NewProjectModal
        user={user}
        isOpen={isNewProjectModalOpen}
        onClose={onNewProjectModalClose}
        afterCreate={handleSelectProject}
        onProjectCreated={handleNewProjectCreation}
      />
    </Flex>
  );
};

export default Home;
