import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Flex,
  Heading,
  Input,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

const Sidebar = ({
  user,
  projects,
  setProjects,
  onSelectProject,
  selectedProject,
  isProjectVersionSelected,
  onNewProjectModalOpen,
  onHandleSidebarClick,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  let deleteEvent = false;
  const sortedProjects = [...projects].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const filteredProjects = sortedProjects.filter((project) =>
    project.file_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentProjects = filteredProjects;
  const [isPolling, setIsPolling] = useState(false);
  const COMPLETION_TIME_LIMIT = 20 * 60 * 1000;

  const pollProjectsData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
        {
          action: "getProjects",
          email: user.email,
        }
      );

      if (response.data.statusCode === 200 && response.data.body) {
        const body = JSON.parse(response.data.body);
        if (body.projects && body.projects.length > 0) {
          let allCompleted = true;

          for (let project of body.projects) {
            if (!project.recommendation_text || !project.pl_violation) {
              allCompleted = false;
              break;
            }
          }
          setProjects(body.projects);

          if (allCompleted) {
            setIsPolling(false);
          }
        }
      } else {
        console.error("Error retrieving projects during polling:", response);
      }
    } catch (error) {
      console.error("Error polling project data:", error);
    }
  };

  const deleteProject = async (docId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
        {
          action: "deleteProject",
          email: user.email,
          project_id: docId,
        }
      );
      if (selectedProject && selectedProject.id === docId) {
        window.location.reload();
      } else {
        pollProjectsData();
      }
    }
  };

  useEffect(() => {
    let interval;
    if (isPolling) {
      interval = setInterval(() => {
        pollProjectsData();
      }, 10000); // Poll every 10 seconds, adjust as needed
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPolling]);

  const projectDataLoaded = (project) => {
    if (
      project.recommendation_text &&
      project.pl_violation &&
      project.flesch &&
      project.dale_chall &&
      project.mlwf_with_stop &&
      project.mlwf_without_stop
    ) {
      return true;
    } else {
      return false;
    }
  };

  const requiresPolling = (project) => {
    const projectCreatedTime = new Date(project.created_at).getTime();
    const currentTime = new Date().getTime();
    const timeSinceCreation = currentTime - projectCreatedTime;

    // Project requires polling if it is not complete, not in error, and within the time limit
    return (
      !projectDataLoaded(project) && timeSinceCreation <= COMPLETION_TIME_LIMIT
    );
  };

  useEffect(() => {
    let shouldPoll = projects.some(requiresPolling);
    setIsPolling(shouldPoll);
  }, [projects]);

  const handleTableRowClick = (project) => {
    if (
      selectedProject &&
      !isProjectVersionSelected &&
      selectedProject.id === project.id
    )
      return;
    if (!project.recommendation_text || !project.pl_violation) return;
    onSelectProject(project);
  };

  const getStatusDisplay = (project) => {
    const completionTimeLimit = 20 * 60 * 1000; // 20 minutes in milliseconds
    const projectCreatedTime = new Date(project.created_at).getTime();
    const currentTime = new Date().getTime();
    const timeSinceCreation = currentTime - projectCreatedTime;

    if (projectDataLoaded(project)) {
      return <Text>Completed</Text>;
    } else if (timeSinceCreation <= completionTimeLimit) {
      return (
        <Text style={{ fontStyle: "italic", color: "lightgrey" }}>
          Processing File
        </Text>
      );
    } else {
      return <Text style={{ color: "lightcoral" }}>Error Processing</Text>;
    }
  };

  return (
    <Box
      direction="column"
      display="flex"
      alignItems="center"
      borderColor="gray.300"
      borderWidth="1px"
      borderRadius="lg"
      height="100vh"
    >
      <Flex direction="column" alignItems="center" height="100vh" flex="1">
        <Box width="100%">
          <Flex justifyContent="flex-start" mt={4} ml={4}>
            <Button
              size={"md"}
              colorScheme="red"
              onClick={onNewProjectModalOpen}
            >
              + Create New
            </Button>
          </Flex>
        </Box>

        <Box width="85%" mt={4}>
          <Flex justifyContent="center" mt={-4} mb={4}>
            <Heading as="h2" size="xl">
              Projects
            </Heading>
          </Flex>
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search projects"
            mb={4}
            sx={{
              "::placeholder": {
                color: "grey",
              },
            }}
          />
          <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            borderBottom={"1px"}
          >
            <Heading fontSize="md" pb={"2"} pr={"2"}>
              Name
            </Heading>
            <Heading fontSize="md" pb={"2"} pr={"2"}>
              Created
            </Heading>
            <Heading fontSize="md" pb={"2"} pr={"2"}>
              Status
            </Heading>
          </Box>
          {currentProjects.length > 0 ? (
            <>
              <Box overflowY={"auto"} maxHeight={"600px"}>
                <Table variant="simple">
                  <Tbody>
                    {currentProjects.map((project, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          if (!deleteEvent && projectDataLoaded(project)) {
                            handleTableRowClick(project);
                          }
                        }}
                        _hover={{
                          cursor: projectDataLoaded(project)
                            ? "pointer"
                            : "not-allowed",
                          bg: projectDataLoaded(project)
                            ? "gray.200"
                            : "transparent",
                        }}
                        backgroundColor={
                          selectedProject && project.id === selectedProject.id
                            ? "gray.200"
                            : "transparent"
                        }
                      >
                        <Td
                          maxWidth={"175px"}
                          borderTopLeftRadius={
                            selectedProject && project.id === selectedProject.id
                              ? "8px"
                              : "0"
                          }
                          borderBottomLeftRadius={
                            selectedProject && project.id === selectedProject.id
                              ? "8px"
                              : "0"
                          }
                          _hover={{
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          }}
                        >
                          {project.file_name}
                        </Td>
                        <Td>
                          {new Date(project.created_at).toLocaleDateString()}
                        </Td>
                        <Td
                          justifyContent={"space-between"}
                          borderTopRightRadius={
                            selectedProject && project.id === selectedProject.id
                              ? "8px"
                              : "0"
                          }
                          borderBottomRightRadius={
                            selectedProject && project.id === selectedProject.id
                              ? "8px"
                              : "0"
                          }
                          _hover={{
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          }}
                        >
                          {getStatusDisplay(project)}
                        </Td>
                        <Td padding={"0"}>
                          <Button
                            fontSize={"sm"}
                            onClick={() => {
                              deleteEvent = true;
                              deleteProject(project.id);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          ) : (
            <Flex justifyContent="center" mt={4}>
              <Text>No Projects Available</Text>
            </Flex>
          )}
        </Box>
      </Flex>
      <Box
        height={"30%"}
        onClick={onHandleSidebarClick}
        alignContent={"center"}
        cursor={"pointer"}
      >
        <ChevronLeftIcon />
      </Box>
    </Box>
  );
};

export default Sidebar;
