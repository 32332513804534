import axios from "axios";

export const CreateProjectVersion = async (
  email,
  versionName,
  project,
  fileName
) => {
  const { id, file_name } = project;

  try {
    const response = await axios.post(
      process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
      {
        action: "createProjectVersion",
        username: email,
        version_name: versionName,
        project_id: id,
        project_name: file_name,
        document_name: fileName,
      }
    );

    if (response.status === 200) {
      const documentVersionId = response.data;
      return documentVersionId;
    } else {
      console.error("Error creating document version:", response);
    }
  } catch (error) {
    console.error("Error creating document version:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetProjectVersions = async (email, project_id) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
      {
        action: "getProjectVersions",
        username: email,
        project_id: project_id,
      }
    );

    if (response.data.statusCode === 200 && response.data.body) {
      const body = JSON.parse(response.data.body);
      if (body.projects && body.projects.length > 0) {
        return body.projects;
      }
    } else {
      console.error("Error retrieving project versions: ", response);
    }
  } catch (error) {
    console.error("Error retrieving project versions: ", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetFolderId = async (email) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
      {
        action: "getFolderId",
        email: email,
      }
    );
    if (response.data.statusCode === 200) {
      const folder = JSON.parse(response.data.body);
      return folder.folderId;
    } else {
      console.error("Error fetching folderId:", response);
    }
  } catch (error) {
    console.error("Error fetching folderId:", error);
  }
};

export const StartDocumentVersionProcessing = async (
  bucket,
  key,
  signedUrl,
  docId,
  docVerId
) => {
  const api = process.env.REACT_APP_PLAINLANGUAGE_METRICS_API_URL;

  const params = {
    Records: [
      {
        s3: {
          bucket: {
            name: bucket,
          },
          object: {
            key: key,
            url: signedUrl,
            documentId: docId,
            documentVersionId: docVerId,
          },
        },
      },
    ],
  };

  try {
    const response = await axios.post(api, params);
    const responseBody = JSON.parse(response.data.body);
    const uniqueKey = responseBody.unique_key;
    return uniqueKey;
  } catch (error) {
    console.error(
      `Error calling PlainLanguageProcessor in StartDocumentVersionProcessing:`,
      error
    );
  }
};

export const UpdateDocumentVersion = async (metrics, documentVersionId) => {
  const api = process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL;

  const params = {
    action: "updateDocumentVersion",
    fileKey: metrics,
    documentVersionId: documentVersionId,
  };

  try {
    await axios.post(api, params);
  } catch (error) {
    console.error(
      `Error calling UpdateDocumentVersion in StartDocumentVersionProcessing:`,
      error
    );
  }
};

export const DeleteProjectVersion = async (email, versionId) => {
  const api = process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL;

  const params = {
    action: "deleteProjectVersion",
    email: email,
    version_id: versionId,
  };

  try {
    const response = await axios.post(api, params);
    return response;
  } catch (error) {
    console.error(`Error calling DeleteProjectVersion: `, error);
  }
};
