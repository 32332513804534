import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Button, Text, Flex, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function MyPdfViewer({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);  // Add a zoom state variable

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Box borderWidth="1px" borderRadius="md" p={6} boxShadow="lg">
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Flex>
          <IconButton
            icon={<ChevronLeftIcon />}
            variant="ghost"
            isDisabled={pageNumber <= 1}
            onClick={() => setPageNumber(prevPageNumber => prevPageNumber - 1)}
          >
            Previous
          </IconButton>
          <Text mt={2} mx={4}>Page {pageNumber} / {numPages}</Text>
          <IconButton
            icon={<ChevronRightIcon />}
            variant="ghost"
            isDisabled={pageNumber >= numPages}
            onClick={() => setPageNumber(prevPageNumber => prevPageNumber + 1)}
          >
            Next
          </IconButton>
        </Flex>
        <Flex>
          <IconButton
            icon={<FontAwesomeIcon icon={faSearchMinus} />}
            variant="ghost"
            isDisabled={zoom <= 0.5}
            onClick={() => setZoom(zoom * 0.9)}
          >
            Zoom Out
          </IconButton>
          <IconButton
            icon={<FontAwesomeIcon icon={faSearchPlus} />}
            variant="ghost"
            isDisabled={zoom >= 1.75}
            onClick={() => setZoom(zoom * 1.1)}
          >
            Zoom In
          </IconButton>
        </Flex>
      </Flex>
      <Box
        borderWidth="1px"
        borderRadius="md"
        overflow="scroll"
        justifyContent="center"
        alignItems="center"
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={zoom} />
        </Document>
      </Box>
    </Box>

  );

}
