import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Text,
  VStack,
  Progress,
  Flex,
  Heading,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DocumentUploader, deleteFile } from "../../components/documentHandler";
import { MyPdfViewer } from "../../components/viewPdf";
import { callFileExtractor } from "../../components/callFileExtractor";
import Confetti from "react-confetti";
import { getAgencyIcons } from "../../components/agencyIcons/index";

const NewProjectModal = ({ user, isOpen, onClose, onProjectCreated }) => {
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const [folderId, setFolderId] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [agencies, setAgencies] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(
    "Preparing to process..."
  );
  const [docId, setDocId] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [runConfetti, setRunConfetti] = useState(false);
  const modalContentRef = React.useRef(); // Ref for the modal content
  const [modalSize, setModalSize] = useState({ width: 0, height: 0 });
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const map = getAgencyIcons();
    const mapArray = Object.entries(map);

    mapArray.sort((a, b) => a[0].localeCompare(b[0]));

    setAgencies(mapArray);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
          {
            action: "getFolderId",
            email: user.email,
          }
        );
        if (response.data.statusCode === 200) {
          const folder = JSON.parse(response.data.body);
          setFolderId(folder.folderId);
        } else {
          console.error("Error fetching folderId:", response);
        }
      } catch (error) {
        console.error("Error fetching folderId:", error);
      }
    };

    fetchData();
  }, [user.email]);

  useEffect(() => {
    if (showThankYou && modalContentRef.current) {
      const { width, height } = modalContentRef.current.getBoundingClientRect(); // Get dimensions
      setModalSize({ width, height }); // Update state with dimensions
    }
  }, [showThankYou]);

  useEffect(() => {
    if (showThankYou) {
      setRunConfetti(true); // Start the confetti when thank you is shown
      setCountdown(10); // Reset countdown to 10 seconds whenever thank you is shown

      const countdownInterval = setInterval(() => {
        setCountdown((prevCount) => Math.max(prevCount - 1, 0)); // Decrement the countdown
      }, 1000);

      const closeTimeout = setTimeout(() => {
        onClose(); // Close the modal after 10 seconds
      }, 10000); // 10 seconds

      return () => {
        clearInterval(countdownInterval); // Cleanup the interval on component unmount
        clearTimeout(closeTimeout); // Prevent modal from closing if the component unmounts early
      };
    }
  }, [showThankYou, onClose]);

  const formik = useFormik({
    initialValues: {
      fileName: "",
      agency: ["Army"],
      fileUrl: "",
    },
    validationSchema: Yup.object({
      fileName: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setLoadingMessage("Submitting your file for processing...");
      try {
        const response = await axios.post(
          process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
          {
            action: "createDocument",
            username: user.email,
            file_name: values.fileName,
            agency: values.agency,
            document_name: uploadedFileName,
          }
        );

        if (response.data.statusCode === 200) {
          const body = JSON.parse(response.data.body);
          const documentId = body.documentId;
          setDocId(documentId);

          const s3Key = `${folderId}/${values.fileName}/${uploadedFileName}`;
          callFileExtractor(
            process.env.REACT_APP_PLAINLANGUAGE_S3_BUCKET,
            s3Key,
            uploadedFile.url,
            documentId
          )
            .then((metrics) => {
              axios
                .post(process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL, {
                  action: "updateDocument",
                  fileKey: metrics,
                  documentId: documentId,
                })
                .catch((err) => {
                  console.error(`Error updating document with key: ${err}`);
                });
              onProjectCreated();
              setShowThankYou(true);
              setRunConfetti(true);
              setTimeout(() => {
                setShowThankYou(false);
                setRunConfetti(false);
                setIsLoading(false);
                onClose(); // Close the modal after a short delay
              }, 1000000); // Adjust delay as needed
            })
            .catch((err) => {
              console.error(`Error extracting file: ${err}`);
              setLoadingMessage(
                "There was an error processing your file. Please try again or contact support."
              );
              setIsLoading(false);
            });
        } else {
          console.error("Error creating project:", response);
          setLoadingMessage(
            "Failed to create project. Please try again or contact support."
          );
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error creating project:", error);
        setLoadingMessage(
          "An unexpected error occurred. Please try again or contact support."
        );
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      setShowThankYou(false);
      setRunConfetti(false);
      setLoadingProgress(0);
      setUploadedFileName(null);
      setUploadedFile(null);
      setIsLoading(false);
      setLoadingMessage("Preparing to process...");
      formik.resetForm();
    }
  }, [isOpen]);

  const handleCancel = async () => {
    setIsLoading(true); // Set loading state to prevent other actions during the process

    try {
      // Delete the uploaded file from storage if it exists
      if (uploadedFile) {
        await deleteFile(folderId, formik.values.fileName, uploadedFileName);
      }

      const hasDbData = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
        {
          action: "getProjectData",
          email: user.email,
          project_id: docId,
        }
      );

      if (hasDbData) {
        await axios.post(process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL, {
          action: "deleteProject",
          email: user.email,
          project_id: docId,
        });
      }

      // Close the modal and reset any state as necessary
      onClose();
    } catch (error) {
      console.error("Error during cancellation:", error);
      toast({
        title: "Cancellation Error",
        description:
          "There was an issue canceling the upload. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Reset loading state
      formik.resetForm();
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent ref={modalContentRef}>
        {showThankYou ? (
          <ModalBody pt={8} pb={6}>
            <Flex
              direction="column"
              align="center"
              justify="center"
              height="100%"
            >
              <Heading mt={8} as="h2" size="lg" mb={4}>
                Submission Successful!
              </Heading>
              <Text mb={8}>
                Your file is now being processed. You will be redirected in{" "}
                {countdown} seconds.
              </Text>
              {runConfetti && (
                <Confetti
                  width={modalSize.width}
                  height={modalSize.height}
                  recycle={false}
                />
              )}
            </Flex>
          </ModalBody>
        ) : (
          <>
            <ModalBody pt={8} pb={6}>
              <Flex
                direction={["column", "column", "row"]}
                justify="space-between"
              >
                <Box mb={6} flexBasis={["100%", "100%", "45%"]}>
                  <Heading as="h2" size="md" mb={4}>
                    {" "}
                    Instructions{" "}
                  </Heading>
                  <Text>
                    1. <strong>Naming Your Project:</strong> Click on the text
                    box under 'Project Name' to enter the name of your project.
                    This name is important for identifying your projects later,
                    so make it descriptive and relevant.
                  </Text>
                  <Text mt={4}>
                    2. <strong>Uploading Your File:</strong> Underneath the
                    'Project Name' box, you'll see a 'Upload File' label. Click
                    here to open a file selection dialog. Please note, only PDF
                    files are compatible for upload.
                  </Text>
                  <Text mt={4}>
                    3. <strong>File Preview:</strong> Once you have selected a
                    file, a preview of the document will be displayed below for
                    PDF file types only. This allows you to verify that you have
                    selected the correct file.
                  </Text>
                  <Text mt={4}>
                    4. <strong>Save & Process or Cancel:</strong> Once you're
                    satisfied with your project name and chosen file, you have
                    two options:
                  </Text>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <strong>Save & Process File:</strong> Click the 'Save &
                      Process File' button on the right side of the modal. This
                      will initiate the file processing where we calculate
                      metrics like Flesch Readability, Dale-Chall Readability,
                      and Mean Word Log Frequency. Please note, this processing
                      might take some time (up to ten minutes) and the buttons
                      will be disabled during this process.
                    </ListItem>
                    <ListItem>
                      <strong>Cancel:</strong> If you wish to discard the
                      changes you made, simply click the 'Cancel' button on the
                      left side of the modal. If a file has been uploaded, it
                      will be deleted from our server and you can start anew.
                    </ListItem>
                  </UnorderedList>
                  <Text mt={4}>
                    That's it! You're now ready to start creating and grading
                    your own projects. Happy analyzing!
                  </Text>
                </Box>
                <Box flexBasis={["100%", "100%", "45%"]}>
                  {isLoading ? (
                    <VStack>
                      <Text>{loadingMessage}</Text>
                      <Box>
                        <Progress
                          size="md"
                          value={loadingProgress}
                          colorScheme="blue"
                          hasStripe
                          isAnimated
                        />
                      </Box>
                    </VStack>
                  ) : (
                    <form onSubmit={formik.handleSubmit}>
                      <FormControl isRequired>
                        <FormLabel>Project Name</FormLabel>
                        <Input
                          ref={initialRef}
                          placeholder="Project Name"
                          id="fileName"
                          name="fileName"
                          onChange={formik.handleChange}
                          value={formik.values.fileName}
                          sx={{
                            "::placeholder": {
                              color: "grey",
                            },
                          }}
                        />
                        {/*
                        <FormLabel>Agency Style Guide</FormLabel>
                        <Select
                          placeholder="Select Agency"
                          name="agency"
                          onChange={(e) => {
                            formik.setFieldValue("agency", [e.target.value]);
                          }}
                          value={formik.values.agency[0]}  // Use the first value of the array for now
                        >
                          {agencies.map(agency => (
                            <option key={agency.Agency} value={agency.Agency}>
                              {agency.Agency}
                            </option>
                          ))}
                        </Select>
                          */}
                        {!uploadedFile && (
                          <FormLabel mt={3}>Upload File</FormLabel>
                        )}
                        {!uploadedFile && (
                          <DocumentUploader
                            userFolderId={folderId}
                            projectName={formik.values.fileName}
                            onUpload={setUploadedFile}
                            onUploadFileName={setUploadedFileName}
                            isDisabled={!formik.values.fileName}
                          />
                        )}
                        {uploadedFile && uploadedFile.extension === "pdf" && (
                          <Box>
                            <Text fontSize="md" pt={5}>
                              PDF Preview:
                            </Text>
                            <MyPdfViewer file={uploadedFile.url} />
                          </Box>
                        )}
                        {uploadedFile && uploadedFile.extension !== "pdf" && (
                          <Box>
                            <Text fontSize="md" pt={5}>
                              File Successfully Uploaded!
                            </Text>
                          </Box>
                        )}
                      </FormControl>
                    </form>
                  )}
                </Box>
              </Flex>
            </ModalBody>
            <Divider borderColor="gray.300" />
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <Button onClick={handleCancel} isDisabled={isLoading}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  ml={3}
                  onClick={formik.handleSubmit}
                  isDisabled={!uploadedFile || isLoading}
                >
                  Save & Process File
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default NewProjectModal;
