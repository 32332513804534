import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  Box,
  useColorMode,
  Button,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import {
  getAgencyIcons,
  getPlaceHolderIcon,
} from "../../components/agencyIcons/index";

const placeholderIcon = getPlaceHolderIcon();

function getAgencyIcon(logoName) {
  if (!logoName) return placeholderIcon;
  const imagePath = logoName;
  return imagePath;
}

const AgencyCard = ({ agency }) => {
  return (
    <Button
      width="200px"
      height="200px"
      m={4}
      onClick={() => window.open(agency.link, "_blank")}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      padding={2}
      p={4}
    >
      <Image
        src={agency.icon}
        boxSize="100px"
        width="150px"
        mb={2}
        objectFit="contain"
      />
      <Box
        width="100%"
        mt={2}
        overflow="hidden"
        textAlign="center"
        whiteSpace="normal" // Ensure the text wraps
      >
        <Text
          fontSize="sm"
          width="100%" // Ensure it takes the full width of the container
        >
          {agency.name}
        </Text>
      </Box>
    </Button>
  );
};

const tabLabels = [
  "A-C",
  "D-F",
  "G-I",
  "J-L",
  "M-O",
  "P-R",
  "S-U",
  "V-X",
  "Y-Z",
];

const StyleGuides = () => {
  const { colorMode } = useColorMode();
  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    const map = getAgencyIcons();
    const mapArray = Object.entries(map);

    const agenciesData = mapArray.map(([agencyName, agencyData]) => ({
      name: agencyName,
      link: agencyData.link,
      icon: getAgencyIcon(agencyData.logo),
    }));

    setAgencies(agenciesData);
  }, []);

  const getAgenciesForTab = (start, end) => {
    return agencies.filter(
      (agency) =>
        agency.name &&
        agency.name[0].toUpperCase() >= start &&
        agency.name[0].toUpperCase() <= end
    );
  };

  return (
    <Flex
      direction="column"
      maxHeight="100vh"
      p={8}
      bg={colorMode === "light" ? "gray.50" : "gray.800"}
      color={colorMode === "light" ? "black" : "white"}
      flex="1"
      flexDirection="column"
    >
      <Heading as="h1" size="2xl" textAlign="center" mb={8}>
        Agency Style Guides
      </Heading>
      <Text fontSize="lg" textAlign="center" mb={8}>
        Select an agency to view its style guide.
      </Text>

      <Tabs
        align="center"
        variant="soft-rounded"
        w="full"
        mb={8}
        flex="1"
        overflowY="auto"
      >
        <TabList>
          {tabLabels.map((label) => (
            <Tab key={label}>{label}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabLabels.map((label) => {
            const [start, end] = label.split("-");
            return (
              <TabPanel key={label}>
                <Flex wrap="wrap" justifyContent="center">
                  {getAgenciesForTab(start, end).map((agency) => (
                    <AgencyCard key={agency.name} agency={agency} />
                  ))}
                </Flex>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default StyleGuides;
