import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./UserRegistration.css";
import plainlanguageLogoBlack from "../../assets/PlainLanguageProBlack.png";
import {
  signUp,
  confirmRegistration,
  login,
  associateSoftwareToken,
  setupSoftwareTokenMfa,
  signOut,
} from "../../api/user";
import QRCode from "qrcode.react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Text,
  List,
  ListItem,
  Icon,
  Alert,
  AlertIcon,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

const generateTotpUri = (email) => {
  return `otpauth://totp/AWSCognito:${email}?secret=BASE32SECRET&issuer=AWSCognito`;
};

function Register() {
  const navigate = useNavigate();
  const [registrationError, setRegistrationError] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const toast = useToast();

  const generateUserFolderId = () => {
    return uuidv4();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(1, "Must be at least 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(1, "Must be at least 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\S]{12,}$/,
        "Must contain at least 12 characters, one uppercase, one lowercase, one number and one special case character"
      )
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      mfaCode: "",
      user: null,
      verificationCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(e) {
    e.preventDefault();

    if (!formik.isValid) {
      return;
    }

    try {
      switch (currentStep) {
        case 1:
          const signUpResult = await signUp(
            formik.values.email,
            formik.values.password,
            formik.values.firstName,
            formik.values.lastName
          );
          setCurrentStep(2);
          break;

        case 2:
          await confirmRegistration(
            formik.values.email,
            formik.values.verificationCode
          );
          const loginResult = await login(
            formik.values.email,
            formik.values.password
          );
          formik.setFieldValue("user", loginResult.user);
          const userFolderId = generateUserFolderId();
          handleCreateUser(formik.values.email, userFolderId);
          //Skipping MFA Step until later date
          //setCurrentStep(3);

          toast({
            title: "Account created.",
            description:
              "Your account has been successfully created and verified.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          navigate("/login");
          break;

        case 3:
          await associateSoftwareToken(
            formik.values.email,
            formik.values.password
          );
          await setupSoftwareTokenMfa(
            formik.values.user,
            formik.values.mfaCode
          );
          signOut();
          navigate("/login"); // Redirect the user to the login page
          break;

        default:
          break;
      }
    } catch (error) {
      console.error("Error during registration process:", error);
      setRegistrationError(error.message);
    }
  }

  function isButtonDisabled() {
    switch (currentStep) {
      case 1:
        return !(
          formik.touched.firstName &&
          formik.touched.lastName &&
          formik.touched.email &&
          formik.touched.password &&
          formik.touched.confirmPassword &&
          formik.isValid
        );
      case 2:
        return formik.values.verificationCode.length === 0;
      case 3:
        return formik.values.mfaCode.length === 0;
      default:
        return true;
    }
  }

  async function handleCreateUser(email, userFolderId) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CREATE_API_URL,
        {
          action: "createUser",
          username: email,
          email: email,
          folder_id: userFolderId,
        }
      );
      console.log(response);

      if (response.data.statusCode === 200) {
        console.log("User created successfully");
      } else {
        console.error("Error creating user:", response);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  useEffect(() => {
    if (registrationError) {
      toast({
        title: "Registration Error",
        description: registrationError,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  }, [registrationError, toast]);

  return (
    <Flex direction="column" align="center" justify="center" minHeight="100vh">
      <Box className="registration-form-container">
        {currentStep === 1 && (
          <Box textAlign="left">
            <Button onClick={() => navigate("/login")} variant="link">
              Return to Login
            </Button>
          </Box>
        )}
        <Image
          src={plainlanguageLogoBlack}
          alt="PlainLanguagePro"
          className="registration-logo"
        />
        <Heading size="lg" className="registration-header-container">
          Create an Account
        </Heading>
        <Text className="registration-header-container">
          To create a new account, please enter your first name, last name,
          email address and choose a password. After submitting the form, you'll
          be directed to the account verification page.
        </Text>
        <form onSubmit={handleSubmit} className="registration-form">
          {currentStep === 1 && (
            <>
              <Flex>
                <FormControl
                  isRequired
                  mb={4}
                  isInvalid={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  w="50%"
                  pr={2}
                >
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    focusBorderColor="black"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Box className="error-message">
                      {formik.errors.firstName}
                    </Box>
                  )}
                </FormControl>
                <FormControl
                  isRequired
                  mb={4}
                  isInvalid={formik.touched.lastName && formik.errors.lastName}
                  w="50%"
                  pl={2}
                >
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    focusBorderColor="black"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Box className="error-message">
                      {formik.errors.lastName}
                    </Box>
                  )}
                </FormControl>
              </Flex>
              <FormControl
                isRequired
                mb={4}
                isInvalid={formik.touched.email && formik.errors.email}
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="black"
                />
                {formik.touched.email && formik.errors.email && (
                  <Box className="error-message">{formik.errors.email}</Box>
                )}
              </FormControl>
              <FormControl
                isRequired
                mb={4}
                isInvalid={formik.touched.password && formik.errors.password}
              >
                <FormLabel
                  htmlFor="password"
                  display="flex"
                  alignItems="center"
                >
                  Password
                </FormLabel>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="black"
                />
                {formik.touched.password && formik.errors.password && ""}
                <Box>
                  <Text fontSize="sm" color="black" mt={4}>
                    {" "}
                    Password requirements:
                  </Text>
                  <List ml={1}>
                    <ListItem fontSize="sm">
                      <Icon
                        as={
                          formik.values.password.length >= 12
                            ? CheckIcon
                            : CloseIcon
                        }
                        color={
                          formik.values.password.length >= 12
                            ? "green.500"
                            : "red.500"
                        }
                        mr={2}
                      />
                      At least 12 characters
                    </ListItem>
                    <ListItem fontSize="sm">
                      <Icon
                        as={
                          /[A-Z]/.test(formik.values.password)
                            ? CheckIcon
                            : CloseIcon
                        }
                        color={
                          /[A-Z]/.test(formik.values.password)
                            ? "green.500"
                            : "red.500"
                        }
                        mr={2}
                      />
                      One uppercase letter
                    </ListItem>
                    <ListItem fontSize="sm">
                      <Icon
                        as={
                          /[a-z]/.test(formik.values.password)
                            ? CheckIcon
                            : CloseIcon
                        }
                        color={
                          /[a-z]/.test(formik.values.password)
                            ? "green.500"
                            : "red.500"
                        }
                        mr={2}
                      />
                      One lowercase letter
                    </ListItem>
                    <ListItem fontSize="sm">
                      <Icon
                        as={
                          /\d/.test(formik.values.password)
                            ? CheckIcon
                            : CloseIcon
                        }
                        color={
                          /\d/.test(formik.values.password)
                            ? "green.500"
                            : "red.500"
                        }
                        mr={2}
                      />
                      One number
                    </ListItem>
                    <ListItem fontSize="sm">
                      <Icon
                        as={
                          new RegExp("[^\\w\\s]").test(formik.values.password)
                            ? CheckIcon
                            : CloseIcon
                        }
                        color={
                          new RegExp("[^\\w\\s]").test(formik.values.password)
                            ? "green.500"
                            : "red.500"
                        }
                        mr={2}
                      />
                      One special character
                    </ListItem>
                    <ListItem fontSize="sm" mt={2}>
                      The following characters count as special characters:
                      <br />
                      {
                        "^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + ="
                      }
                    </ListItem>
                  </List>
                </Box>
              </FormControl>
              <FormControl
                isRequired
                mb={4}
                mt={4}
                isInvalid={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              >
                <FormLabel htmlFor="confirmPassword">
                  Confirm Password
                </FormLabel>
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="black"
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <Box className="error-message">
                      {formik.errors.confirmPassword}
                    </Box>
                  )}
              </FormControl>
              <Button
                type="submit"
                w="100%"
                mt={4}
                backgroundColor={isButtonDisabled() ? "gray" : "black"}
                color="white"
                _hover={{
                  backgroundColor: isButtonDisabled() ? "gray" : "gray.700",
                }}
                isDisabled={isButtonDisabled()}
              >
                Register
              </Button>
            </>
          )}
          {currentStep === 2 && (
            <>
              <Text>Enter the verification code sent to your email:</Text>
              <FormControl isRequired mb={4}>
                <FormLabel htmlFor="verificationCode">
                  Verification Code
                </FormLabel>
                <Input
                  type="text"
                  id="verificationCode"
                  name="verificationCode"
                  value={formik.values.verificationCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="black"
                />
              </FormControl>
              <Button
                type="submit"
                w="100%"
                mt={4}
                backgroundColor={isButtonDisabled() ? "gray" : "black"}
                color="white"
                _hover={{
                  backgroundColor: isButtonDisabled() ? "gray" : "gray.700",
                }}
                isDisabled={isButtonDisabled()}
              >
                Confirm Registration
              </Button>
            </>
          )}
          {currentStep === 3 && (
            <>
              <Text>
                Scan the QR code with your MFA app and enter the generated MFA
                code:
              </Text>
              <QRCode value={generateTotpUri(formik.values.email)} />
              <FormControl>
                <FormLabel htmlFor="mfaCode">MFA Code</FormLabel>
                <Input
                  type="text"
                  id="mfaCode"
                  name="mfaCode"
                  value={formik.values.mfaCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="black"
                />
              </FormControl>
              <Button
                type="submit"
                w="100%"
                mt={4}
                backgroundColor={isButtonDisabled() ? "gray" : "black"}
                color="white"
                _hover={{
                  backgroundColor: isButtonDisabled() ? "gray" : "gray.700",
                }}
                isDisabled={isButtonDisabled()}
              >
                Verify MFA
              </Button>
            </>
          )}
        </form>
      </Box>
    </Flex>
  );
}

export default Register;
