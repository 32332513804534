import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import plainlanguageLogoBlack from "../../assets/PlainLanguageProBlack.png";
import { forgotPassword, confirmForgotPassword } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import ResetPassword from "../forgotPassword/ResetPassword";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { login } from "../../api/user";
import * as routes from "../../constants/routes";

const ChangePassword = ({ email, handleChangePassword, onCancel }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangePassword(email, oldPassword, newPassword);
  };

  return (
    <Box>
      <Heading as="h2">Change Password</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="oldPassword" isRequired>
          <FormLabel>Old Password</FormLabel>
          <Input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </FormControl>
        <FormControl id="newPassword" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormControl>
        <Button type="submit">Change Password</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </form>
    </Box>
  );
};

const LoginPage = ({ handleChangePassword }) => {
  const [emailState, setEmailState] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (emailState, password) => {
    try {
      const response = await login(emailState, password);
      const { user, userGroups } = response;

      const { attributes } = user;
      const { given_name, family_name, email } = attributes;

      const authUser = {
        isAuthenticated: true,
        attributes: attributes,
        groups: userGroups,
        firstName: given_name,
        lastName: family_name,
        email: email,
        organization: attributes["custom:organization"] || null,
      };

      auth.login(authUser);
    } catch (error) {
      console.error("Login error:", error);
      if (error.message === "FORCE_CHANGE_PASSWORD") {
        setErrorMessage("Please change your temporary password.");
      } else if (error.code === "UserNotConfirmedException") {
        setErrorMessage(
          "User is not confirmed. Please check your email for the confirmation link."
        );
      } else if (error.code === "NotAuthorizedException") {
        setErrorMessage("Incorrect username or password.");
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await handleLogin(emailState, password);
      setLoading(false);
      navigate(routes.HOME);
    } catch (error) {
      console.error("Login error:", error);
      window.alert("Invalid username or password.");
      if (error.message === "FORCE_CHANGE_PASSWORD") {
        setShowChangePassword(true);
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(emailState);
      setShowResetPassword(true);
    } catch (error) {
      console.error("Forgot password error:", error);
      alert(
        "Error sending password reset email. Please enter a valid email address."
      );
    }
  };

  const onResetPassword = async (emailState, verificationCode, newPassword) => {
    try {
      await confirmForgotPassword(emailState, verificationCode, newPassword);
      setShowResetPassword(false);
      navigate(routes.LOGIN);
    } catch (error) {
      console.error("Reset password error:", error);
      alert("Error resetting password.");
    }
  };

  useEffect(() => {
    const isEmailValid = /\S+@\S+\.\S+/.test(emailState);
    setIsButtonDisabled(!(isEmailValid && password.length > 0));
  }, [emailState, password]);

  if (showResetPassword) {
    return (
      <ResetPassword
        email={emailState}
        onResetPassword={onResetPassword}
        onCancel={() => setShowResetPassword(false)}
      />
    );
  }

  if (showChangePassword) {
    return (
      <ChangePassword
        email={emailState}
        handleChangePassword={handleChangePassword}
        onCancel={() => setShowChangePassword(false)}
      />
    );
  }

  return (
    <Flex
      className="login-page"
      direction="column"
      align="center"
      justify="center"
      minH="100vh"
    >
      <Box className="login-form-container">
        <Image
          src={plainlanguageLogoBlack}
          alt="PlainLanguagePro Logo"
          className="login-logo"
        />
        <Text className="login-header-container" mb={4}>
          Please enter your username and password to access your account. If
          you're new here, feel free to register by clicking on the "Register"
          link below. <br />
          <br />
          Forgot your password? Click on the "Forgot Password" link to reset it.
          Happy to have you with us!
        </Text>
        <form onSubmit={handleSubmit} className="login-form">
          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={emailState}
              onChange={(e) => setEmailState(e.target.value)}
              focusBorderColor="black"
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              focusBorderColor="black"
            />
          </FormControl>
          <Button
            type="submit"
            w="100%"
            mt={4}
            backgroundColor={isButtonDisabled ? "gray" : "black"}
            color="white"
            _hover={{ backgroundColor: isButtonDisabled ? "gray" : "gray.700" }}
            isDisabled={isButtonDisabled}
          >
            {loading ? <Spinner /> : "Login"}
          </Button>
        </form>
        <Flex mt={4} w="100%">
          <Box>
            <Button onClick={() => navigate(routes.REGISTER)} variant="link">
              Don't have an account?
            </Button>
          </Box>
          <Spacer />
          <Box>
            <Button onClick={handleForgotPassword} variant="link">
              Forgot Password?
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LoginPage;
