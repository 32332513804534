export const italicsIdentifier = (sentence) => {
  if (
    sentence.startsWith('"') ||
    (sentence.startsWith("“") && sentence.endsWith('"')) ||
    sentence.endsWith("”")
  ) {
    return true;
  }
};

export const quoteCharSelector = (sentence, obj) => {
  if (sentence.startsWith("“") && sentence.endsWith("”")) {
    obj.prepend = "“";
    obj.append = "”";
  }
  // TODO: if there are sentences that end in quote but dont begin with one use case
};

export const boldContentProcessor = (
  sentence,
  boldedSentObj,
  quotedSent,
  quotedCharObj,
  boldContent
) => {
  boldContent.forEach((word) => {
    if (sentence.includes(word)) {
      let trimmedWord = word.trim();
      if (trimmedWord.endsWith(":") && sentence.startsWith(trimmedWord)) {
        boldedSentObj.boldedSentenceTitle = trimmedWord;
      }

      if (quotedSent) {
        trimmedWord =
          quotedCharObj.prepend + trimmedWord + quotedCharObj.append;
      }

      if (sentence === word || sentence === trimmedWord) {
        boldedSentObj.boldedWholeSentence = true;
        boldedSentObj.boldedSentence = trimmedWord;
        boldedSentObj.boldedSentenceTitle = "";
      }
    }
  });
};

export const italicContentProcessor = (
  sentence,
  italicSentObj,
  quotedSent,
  quotedCharObj,
  italicContent
) => {
  italicContent.forEach((word) => {
    if (sentence.includes(word)) {
      let trimmedWord = word.trim();
      if (quotedSent) {
        trimmedWord =
          quotedCharObj.prepend + trimmedWord + quotedCharObj.append;
      }
      if (sentence === word || sentence === trimmedWord) {
        italicSentObj.italicizedWholeSentence = true;
        italicSentObj.italicizedSentence = trimmedWord;
      }
    }
  });
};

export const formulateFragment = (fragment, replacementText, type) => {
  const element = document.createElement(type);
  element.textContent = replacementText;

  fragment.appendChild(element);
};

export const createRestOfNode = (fragment, replacementText) => {
  const restTextNode = document.createTextNode(replacementText);
  fragment.appendChild(restTextNode);
};

// Function to find and log bold content
export const findBoldContent = (contentWindow, setBoldContent) => {
  let tempBoldContent = [];

  const boldElements = contentWindow.document.querySelectorAll(
    'b, strong, [style*="font-weight: bold"]'
  );
  boldElements.forEach((element) => {
    tempBoldContent.push(element.textContent);
  });
  setBoldContent(tempBoldContent);
};

// Function to find and log italic content
export const findItalicContent = (contentWindow, setItalicContent) => {
  let tempItalicContent = [];

  const italicElements = contentWindow.document.querySelectorAll(
    'i, em, [style*="font-style: italic"]'
  );
  italicElements.forEach((element) => {
    tempItalicContent.push(element.textContent);
  });
  setItalicContent(tempItalicContent);
};
