import React, { useState } from "react";
import LoginPage from "./pages/login/LoginPage";
import { forgotPassword, confirmForgotPassword, signUp } from "./api/user";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./contexts/auth";

import "./App.css";
import Header from "./components/header";
import Home from "./pages/home/Home";
import UserRegistration from "./pages/register/UserRegistration";
import ObfuscationPage from "./pages/login/ObfuscationPage";
import { LandingPage } from "./pages/landing";
import Profile from "./pages/Profile";
import { FileEditor } from "./editor";
import StyleGuides from "./pages/styleGuides/StyleGuides";
import { Navigate } from "react-router-dom";
import * as routes from "./constants/routes";

const App = () => {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState(null);
  const isAuthenticated = auth.isAuthenticated;

  const handleForgotPassword = async (email) => {
    try {
      await forgotPassword(email);
      alert("Verification code sent. Please check your email.");
    } catch (error) {
      console.error("Forgot password error:", error);
      alert("Error sending verification code.");
    }
  };

  const handleConfirmForgotPassword = async (
    email,
    verificationCode,
    newPassword
  ) => {
    try {
      await confirmForgotPassword(email, verificationCode, newPassword);
      alert("Password successfully reset.");
      return true;
    } catch (error) {
      console.error("Confirm forgot password error:", error);
      alert("Error resetting password.");
      return false;
    }
  };

  const handleRegister = async (email, password) => {
    try {
      await signUp(email, password);
      // Redirect the user to the confirmation page or display a success message
    } catch (error) {
      console.error("Registration error:", error);
      // Handle the error, e.g., display an error message
    }
  };

  return (
    <>
      <div className="app-container">
        {isAuthenticated && <Header user={auth.user} />}
        <Routes>
          {/* Obfuscation Page */}
          <Route
            path={routes.OBFUSCATION}
            element={
              <ObfuscationPage
                onObfuscationPassed={() => auth.obfuscated(true)}
              />
            }
          />

          {/* Login Page */}
          <Route
            path={routes.LOGIN}
            element={
              !auth.isObfuscationPassed ? (
                <Navigate to={routes.OBFUSCATION} />
              ) : isAuthenticated ? (
                <Navigate to={routes.HOME} />
              ) : (
                <LoginPage
                  onForgotPassword={handleForgotPassword}
                  onConfirmForgotPassword={handleConfirmForgotPassword}
                />
              )
            }
          />

          {/* Register Page */}
          <Route
            path={routes.REGISTER}
            element={
              !auth.isObfuscationPassed ? (
                <Navigate to={routes.OBFUSCATION} />
              ) : isAuthenticated ? (
                <Navigate to={routes.HOME} />
              ) : (
                <UserRegistration
                  onSignUp={handleRegister}
                  errorMessage={errorMessage}
                />
              )
            }
          />

          {/* Home Page */}
          <Route
            path={routes.HOME}
            element={isAuthenticated && <Home user={auth.user} />}
          />

          {/* Profile Page */}
          <Route
            path={routes.PROFILE}
            element={isAuthenticated && <Profile />}
          />

          {/* Edit Page */}
          <Route
            path={routes.EDIT}
            element={isAuthenticated && <FileEditor />}
          />

          {/* Style Guides Page */}
          <Route
            path={routes.STYLE_GUIDES}
            element={isAuthenticated && <StyleGuides />}
          />

          {/* Landing Page */}
          <Route
            path={routes.LANDING}
            element={
              isAuthenticated ? <Navigate to={routes.HOME} /> : <LandingPage />
            }
          />

          {/* Fallback route */}
          <Route
            path="*"
            element={
              <Navigate
                to={isAuthenticated ? routes.HOME : routes.LANDING}
                replace
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
export default App;
